<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <a-button
        type="primary"
        class="back-btn"
        @click="$router.push({ name: 'opendata-list' })"
      >
        <i class="t-transition icons icon-chevron-left" />
        Назад
      </a-button>

      <a-row class="post-search" type="flex">
        <a-select
          v-model="perPage"
          class="table__parpage"
          option-filter-prop="children"
          placeholder="Все"
          @change="tableperPage"
        >
          <a-select-option
            v-for="(limit, index) in LimitList"
            :key="index"
            :value="limit.value"
          >
            {{ limit.title }}
          </a-select-option>
        </a-select>
        <a-button
          type="primary"
          class="all-news-btn"
          style="margin: 0 15px"
          @click="Allnews"
        >
          Все
        </a-button>
        <a-button type="primary" @click="createFunc()">
          <a-icon type="plus" />{{ $t("Add") }}
        </a-button>
      </a-row>

      <a-row type="flex" style="width: 100%; justify-content: space-between">
        <h1>
          {{ title[$i18n.locale] }}
          /
          {{
            getTitle &&
            getTitle[0] &&
            getTitle[0].title &&
            getTitle[0].title[$i18n.locale]
          }}
        </h1>
        <a-button
          :disabled="cardsIsEmpty"
          type="danger"
          @click="removeItem(selectedPosts)"
        >
          <a-icon class="action-btns" type="delete" />
          <!--{{ $t("Delete") }}-->
        </a-button>
      </a-row>
    </a-row>

    <a-table
      style="overflow-x: auto"
      :columns="columns"
      :data-source="categoryProps.results || []"
      :loading="loading"
      :pagination="false"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
        <span
          class="td-post-item"
          @click="
            $router.push({
              name: 'opendata-docs-update',
              params: { ...$route.params, id: item.id }
            })
          "
        >
          <b>{{ item.id }}</b>
        </span>
      </template>

      <template slot="t_title" slot-scope="item">
        <div
          class="td-post-item"
          @click="
            $router.push({
              name: 'opendata-docs-update',
              params: { ...$route.params, id: item.id }
            })
          "
        >
          <b>{{ item.title || "-" }}</b>
        </div>
      </template>

      <template slot="status" slot-scope="item">
        <!-- <osg-image :img="item" /> -->
        <a-tag :color="item.is_active ? 'geekblue' : 'volcano'">
          {{ item.is_active ? $t("Published") : $t("NotPublished") }}
        </a-tag>
      </template>

      <template slot="created_at" slot-scope="item">
        {{ moment(item.created_at).format("LLL") }}
      </template>

      <template slot="updated_at" slot-scope="item">
        {{ moment(item.updated_at).format("LLL") }}
      </template>

      <template slot="operation" slot-scope="item">
        <a-button
          type="primary"
          class="edit-btn"
          @click="
            $router.push(
              {
                name: 'opendata-docs-update',
                params: { ...$route.params, id: item.id }
              },
              () => null
            )
          "
        >
          <!--{{ $t("Edit") }}-->
          <a-icon class="action-btns" type="edit" />
        </a-button>
        <a-popconfirm
          title="Вы действительно хотите удалить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="removeItem(item.id)"
        >
          <a-button style="margin-right: 10px" type="danger">
            <!--{{ $t("Delete") }}-->
            <a-icon class="action-btns" type="delete" />
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <a-row class="mt-1" style="padding-bottom: 15px">
      <a-pagination
        :key="perPage"
        :current="page"
        :default-page-size="perPage"
        :total="+categoryProps.count || 0"
        @change="toPage"
      />
    </a-row>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      selectedRowKeys: [],
      selectedPosts: [],
      columns: [
        {
          title: "ID",
          key: "id",
          width: 80,
          // fixed: "left",
          scopedSlots: { customRender: "id" }
        },
        {
          title: this.$t("TableTitle"),
          // width: 500,
          key: "t_title",
          scopedSlots: { customRender: "t_title" }
        },
        {
          title: this.$t("Status"),
          key: "status",
          width: 100,
          scopedSlots: { customRender: "status" }
        },
        {
          title: this.$t("TableCreatedDate"),
          key: "created_datetime",
          width: 220,
          scopedSlots: { customRender: "created_at" }
        },
        {
          title: this.$t("TableLastUpdatedDate"),
          key: "updated_datetime",
          width: 220,
          scopedSlots: { customRender: "updated_at" }
        },
        {
          title: this.$t("TableAction"),
          key: "operation",
          width: 140,
          // fixed: "right",
          scopedSlots: { customRender: "operation" }
        }
      ],
      tableProps: [
        /*{
          title: {
            uz: "3299 Nizom bo'yicha ma'lumotlar",
            oz: "3299 Низом бўйича маьлумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 39
        },
        {
          title: {
            uz: "PF-6247 bo'yicha ma'lumotlar",
            oz: "ПФ-6247  бўйича маълумотлар",
            ru: "3299 Низом бўйича маьлумотлар",
            en: "3299 Низом бўйича маьлумотлар"
          },
          id: 40
        }*/
      ],
      title: {
        oz: "Документы",
        uz: "Документы",
        qr: "Документы",
        ru: "Документы",
        en: "Documents"
      },
      search: "",
      categoryProps: {
        results: [],
        count: 0
      },
      LimitList: [
        {
          value: 10,
          title: "10"
        },
        {
          value: 20,
          title: "20"
        },
        {
          value: 40,
          title: "40"
        }
      ],
      cardsIsEmpty: true,
      perPage: 20,
      page: 1,
      offset: 0
    }
  },
  computed: {
    getTitle() {
      return this.tableProps.filter((el) => {
        return el.id === this.$route.params.open_id
      })
    }
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true
    }
  },
  /*async mounted() {
    console.log(this.$route.params)
  },*/
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item === +itemId)
      if (f !== -1) {
        this.selectedPosts.splice(f, 1)
        this.cardsIsEmpty = false
      } else {
        this.selectedPosts.push(itemId)
        this.cardsIsEmpty = false
      }

      if (this.selectedPosts.length === 0) {
        this.cardsIsEmpty = true
      }
      // console.log(this.selectedPosts)
    },
    tableperPage() {
      this.offset = 0
      this.fetchData()
    },
    async Allnews() {
      this.loading = true
      this.perPage = null
      this.categoryProps = {
        results: [],
        count: 0
      }
      try {
        const r = await this.$api.get(
          "/admin/budget/legally-open-information-attachment/list/",
          {
            params: {
              budget_legally_open_information: this.$route.params.list_id
            }
          }
        )
        if (r) {
          this.$set(this, "categoryProps", {
            results: (r && r.data && r.data.results) || []
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    toPage(arg) {
      this.$router.push({ query: { page: arg } })
    },
    SearchInput() {
      this.offset = 0
      this.fetchData()
    },
    async fetchData() {
      // console.log(this.$route.params)
      this.loading = true
      this.offset = +this.$route.query.page || 1
      this.page = this.offset
      this.offset = (this.offset - 1) * this.perPage
      try {
        const r = await this.$api.get(
          "/admin/budget/legally-open-information-attachment/list/",
          {
            params: {
              budget_legally_open_information: this.$route.params.list_id,
              limit: this.perPage,
              offset: this.offset
            }
          }
        )
        if (r) {
          this.$set(this, "categoryProps", {
            results: (r && r.data && r.data.results) || [],
            count: r && r.data && r.data.count
          })
        }
      } catch (e) {
        this.$sentry.captureMessage(e)
      }
      this.loading = false
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const res = await this.$api.delete(
              "/admin/budget/legally-open-information-attachment/" +
                i +
                "/delete/"
            )
            if (res && res.status === 204) {
              this.$message.success("Новости Успешно удалено")
            }
          } catch (e) {
            this.$sentry.captureMessage(e)
          }
        }
      } else {
        try {
          const res = await this.$api.delete(
            "/admin/budget/legally-open-information-attachment/" +
              id +
              "/delete/"
          )
          if (res && res.status === 204) {
            this.$message.success("Новости Успешно удалено")
          }
        } catch (e) {
          this.$sentry.captureMessage(e)
        }
      }
      await this.fetchData()
      this.selectedCards = []
      this.cardsIsEmpty = true
    },
    async createFunc() {
      await this.$router.push({
        name: "opendata-docs-create",
        params: { ...this.$route.params }
      })
    }
  }
}
</script>
